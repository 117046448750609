<template>
    <div>
        <Header></Header>
        <div class="container mx-auto py-4">
            <div class="border border-gray-400 rounded-3xl pt-2 pb-6">
                <div class="w-1/4 mx-auto">
                    <img src="@/assets/logo-tripwe-v.png" class="w-2/4 mx-auto" />
                </div>
                <div class="flex justify-center mt-4" v-if="!isForgotPassword">
                    <button
                        @click="$router.push({ name: 'Login' })"
                        class="bg-gray-500 hover:bg-gray-600 text-white font-semibold rounded-t-2xl py-1 w-32"
                        :class="[isActive('Login') ? 'bg-primary hover:bg-primary-darker' : '']"
                    >
                        LOGIN
                    </button>
                    <button
                        @click="$router.push({ name: 'Register' })"
                        class="bg-gray-500 hover:bg-gray-600 text-white font-semibold rounded-t-2xl py-1 w-32 ml-2"
                        :class="[isActive('Register') ? 'bg-primary hover:bg-primary-darker' : '']"
                    >
                        SIGN UP
                    </button>
                </div>
                <div class="flex justify-center mt-4" v-else>
                    <button
                        @click="$router.push({ name: 'ForgotPassword' })"
                        class="bg-gray-500 hover:bg-gray-600 text-white font-semibold rounded-t-2xl py-1 w-64 ml-2"
                        :class="[isActive('ForgotPassword') ? 'bg-primary hover:bg-primary-darker' : '']"
                    >
                        Forgot Password
                    </button>
                </div>
                <router-view></router-view>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';

    export default {
        name: 'Auth',

        components: {
            Header,
            Footer
        },
        computed: {
            isForgotPassword(){
                return this.$route.matched[1].name == 'ForgotPassword';
            }
        },
        methods: {
            isActive(name) {
                return this.$route.matched[1].name == name;
            },
        }
    };
</script>
